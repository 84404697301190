// @flow
import { Cookies } from "react-cookie";

/**
 * Checks if user is authenticated
 */
const isUserAuthenticated = () => {
  const user = getLoggedInUser();
  if (!user) {
    return false;
  }
  // const decoded = jwtDecode(user.token);
  // const currentTime = Date.now() / 1000;
  // if (decoded.exp < currentTime) {
  //   console.warn("access token expired");
  //   return false;
  // } else {
  //   return true;
  // }
  // if (decoded.exp < currentTime) {
  //   console.warn("access token expired");
  //   return false;
  // } else {
  return true;
  // }
};

/**
 * Returns the logged in user
 */
const getLoggedInUser = () => {
  const cookies = new Cookies();
  const user = cookies.get("refresh_token");
  // return user ? (typeof user == "object" ? user : JSON.parse(user)) : null;
  // const user = localStorage.getItem("access_token");
  return user;
};

export { isUserAuthenticated, getLoggedInUser };
