// @flow
import { FETCH_DATA, FETCH_DATA_SUCCESS, FETCH_DATA_FAILED } from "./constants";

const INIT_STATE = {
  data: null,
  loading: false,
};

type AuthAction = { type: string, payload: {} | string };
type State = { data?: {} | null, loading?: boolean };

const Pages = (state: State = INIT_STATE, action: AuthAction) => {
  switch (action.type) {
    case FETCH_DATA:
      return { ...state, loading: true };
    case FETCH_DATA_SUCCESS:
      return { ...state, data: action.payload, loading: false, error: null };
    case FETCH_DATA_FAILED:
      return { ...state, error: action.payload, loading: false };
    default:
      return { ...state };
  }
};

export default Pages;
