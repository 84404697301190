// @flow
import { Cookies } from "react-cookie";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { fetchJSON, fetchAPI, axiosApiInstance } from "../../helpers/api";
import { devClient } from "../../constants";
import { prodClient } from "../../constants";

import {
  LOGIN_USER,
  LOGOUT_USER,
  REGISTER_USER,
  FORGET_PASSWORD,
} from "./constants";

import {
  loginUserSuccess,
  loginUserFailed,
  registerUserSuccess,
  registerUserFailed,
  forgetPasswordSuccess,
  forgetPasswordFailed,
} from "./actions";

/**
 * Sets the session
 * @param {*} user
 */
const setSession = (user) => {
  let cookies = new Cookies();

  if (user) {
    const { access_token, expires_in, refresh_token } = user.data;
    cookies.set("access_token", access_token, {
      path: "/",
      maxAge: expires_in,
    });
    cookies.set("refresh_token", refresh_token, { path: "/" });
  } else {
    cookies.remove("access_token", { path: "/" });
    cookies.remove("refresh_token", { path: "/" });
  }
};
/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login({ payload: { username, password, status } }) {
  const options = {
    method: "POST",
    data: JSON.stringify({
      username,
      password,
      client_id:
        status === "development" ? devClient.CLIENT_ID : prodClient.CLIENT_ID,
      client_secret:
        status === "development"
          ? devClient.CLIENT_SECRET
          : prodClient.CLIENT_SECRET,
      grant_type: "password",
    }),
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    localStorage.setItem("status", status);
    axiosApiInstance.defaults.baseURL =
      status === "development"
        ? "https://staging.ctatuscolana.it/api"
        : "https://www.ctatuscolana.it/api";
    const response = yield call(fetchAPI, "/auth/token/", options);
    setSession(response);
    yield put(loginUserSuccess(response));
  } catch (error) {
    yield put(loginUserFailed(error.response.data.error_description));
    setSession(null);
  }
}

/**
 * Logout the user
 * @param {*} param0
 */
function* logout({ payload: { history } }) {
  try {
    setSession(null);
    yield call(() => {
      history.push("/account/login");
    });
  } catch (error) {}
}

/**
 * Register the user
 */
function* register({ payload: { fullname, email, password } }) {
  const options = {
    body: JSON.stringify({ fullname, email, password }),
    method: "POST",
    headers: { "Content-Type": "application/json" },
  };

  try {
    const response = yield call(fetchJSON, "/users/register", options);
    yield put(registerUserSuccess(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      default:
        message = error;
    }
    yield put(registerUserFailed(message));
  }
}

/**
 * forget password
 */
function* forgetPassword({ payload: { username } }) {
  const options = {
    body: JSON.stringify({ username }),
    method: "POST",
    headers: { "Content-Type": "application/json" },
  };

  try {
    const response = yield call(fetchJSON, "/users/password-reset", options);
    yield put(forgetPasswordSuccess(response.message));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      default:
        message = error;
    }
    yield put(forgetPasswordFailed(message));
  }
}

export function* watchLoginUser(): any {
  yield takeEvery(LOGIN_USER, login);
}

export function* watchLogoutUser(): any {
  yield takeEvery(LOGOUT_USER, logout);
}

export function* watchRegisterUser(): any {
  yield takeEvery(REGISTER_USER, register);
}

export function* watchForgetPassword(): any {
  yield takeEvery(FORGET_PASSWORD, forgetPassword);
}

function* authSaga(): any {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchForgetPassword),
  ]);
}

export default authSaga;
