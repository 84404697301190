import axios from "axios";
import { Cookies } from "react-cookie";

import { devClient } from "../constants";
import { prodClient } from "../constants";
/**
 * Fetch data from given url
 * @param {*} url
 * @param {*} options
 */
const fetchJSON = (url, options = {}) => {
  return fetch(url, options)
    .then((response) => {
      if (!response.status === 200) {
        throw response.json();
      }
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      throw error;
    });
};

const cookies = new Cookies();

const fetchAPI = async (url, options) => {
  try {
    const response = await axiosApiInstance(url, {
      ...options,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const ENV = localStorage.getItem("status");

axios.defaults.baseURL =
  ENV === "development"
    ? "https://staging.ctatuscolana.it/api"
    : "https://www.ctatuscolana.it/api";

const axiosApiInstance = axios.create();

axiosApiInstance.interceptors.request.use(
  async (config) => {
    config.headers.Authorization = cookies.get("access_token")
      ? `Bearer ${cookies.get("access_token")}`
      : undefined;
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

axiosApiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;

      const options = {
        method: "POST",
        data: JSON.stringify({
          refresh_token: cookies.get("refresh_token"),
          client_id:
            ENV === "development" ? devClient.CLIENT_ID : prodClient.CLIENT_ID,
          client_secret:
            ENV === "development"
              ? devClient.CLIENT_SECRET
              : prodClient.CLIENT_SECRET,
          grant_type: "refresh_token",
        }),
        headers: {
          "Content-Type": "application/json",
        },
      };

      const response = await fetchAPI("/auth/token/", options);
      const { access_token, expires_in, refresh_token } = response.data;
      cookies.set("access_token", access_token, {
        path: "/",
        maxAge: expires_in,
      });
      cookies.remove("refresh_token", { path: "/" });
      cookies.set("refresh_token", refresh_token, { path: "/" });
      return axiosApiInstance(originalRequest);
    }
    return Promise.reject(error);
  },
);

export { fetchJSON, fetchAPI, axiosApiInstance };
