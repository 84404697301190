import React from "react";
import { Redirect } from "react-router-dom";
import { Route } from "react-router-dom";

import { isUserAuthenticated } from "../helpers/authUtils";

// lazy load all the views

// auth
const Login = React.lazy(() => import("../pages/auth/Login"));
const Logout = React.lazy(() => import("../pages/auth/Logout"));
const Register = React.lazy(() => import("../pages/auth/Register"));
const ForgetPassword = React.lazy(() => import("../pages/auth/ForgetPassword"));
const Confirm = React.lazy(() => import("../pages/auth/Confirm"));

// Home
const Home = React.lazy(() => import("../pages/home"));

// apps
// notifcation center
const NotificationCenterApp = React.lazy(() =>
  import("../pages/apps/NotificationCenter"),
);

// accounting
const FornitoriApp = React.lazy(() =>
  import("../pages/apps/Accounting/Fornitori"),
);
const FornitoriEditApp = React.lazy(() =>
  import("../pages/apps/Accounting/FornitoriEdit"),
);
const ListViagiApp = React.lazy(() =>
  import("../pages/apps/Accounting/ListaViagi"),
);
const TipologiaFornitoriApp = React.lazy(() =>
  import("../pages/apps/Accounting/TipologiaFornitori"),
);
const SimulazioneCostoApp = React.lazy(() =>
  import("../pages/apps/Accounting/SimulazioneCosto"),
);
const AggiungiCostoApp = React.lazy(() =>
  import("../pages/apps/Accounting/AddCost"),
);

// tour leader
const TourLeaderApp = React.lazy(() => import("../pages/apps/TourLeader"));

// Partners
const Partners = React.lazy(() => import("../pages/apps/Partner"));
const PartnerDetail = React.lazy(() => import("../pages/apps/Partner/Detail"));

// Agency Accounting
const AgencyAccountingProviderTypes = React.lazy(() =>
  import("../pages/apps/AgencyAccounting/ProviderType"),
);

const AgencyAccountingProviders = React.lazy(() =>
  import("../pages/apps/AgencyAccounting/Provider"),
);

const AgencyAccountingProvidersEdit = React.lazy(() =>
  import("../pages/apps/AgencyAccounting/Provider/Edit"),
);

const WorkingDaysCalendar = React.lazy(() =>
  import("../pages/apps/AgencyAccounting/Setting/WorkingDaysCalendar"),
);

const AgencyAccountingCost = React.lazy(() =>
  import("../pages/apps/AgencyAccounting/Cost"),
);

const PlayCardFaqs = React.lazy(() =>
  import("../pages/apps/PageSettings/Faqs/index"),
);

const IndexComments = React.lazy(() =>
  import("../pages/apps/PageSettings/IndexComments/index"),
);

const IndexLogos = React.lazy(() =>
  import("../pages/apps/PageSettings/IndexLogos/index"),
);

const IndexMoments = React.lazy(() =>
  import("../pages/apps/PageSettings/IndexMoments/index"),
);

const Banner = React.lazy(() =>
  import("../pages/apps/PageSettings/Banner/index"),
);

const IndexCategories = React.lazy(() =>
  import("../pages/apps/PageSettings/IndexCategories/index"),
);

const IndexHero = React.lazy(() =>
  import("../pages/apps/PageSettings/IndexHero/index"),
);

const Bus = React.lazy(() => import("../pages/apps/Bus"));

// handle auth and authorization
const PrivateRoute = ({ component: Component, roles, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (!isUserAuthenticated()) {
        // not logged in so redirect to login page with the return url
        return (
          <Redirect
            to={{ pathname: "/account/login", state: { from: props.location } }}
          />
        );
      }

      // const loggedInUser = getLoggedInUser();
      // check if route is restricted by role
      // if (roles && roles.indexOf(loggedInUser.role) === -1) {
      //   // role not authorised so redirect to home page
      //   return <Redirect to={{ pathname: "/" }} />;
      // }

      // authorised so return component
      return <Component {...props} />;
    }}
  />
);

// dashboards
const homeRoutes = {
  path: "/",
  name: "Home",
  route: PrivateRoute,
  icon: "uil-home-alt",
  component: Home,
  exact: true,
};

const notificationAppRoutes = {
  path: "/apps/notification",
  name: "Notification Center",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "uil-bell",
  component: NotificationCenterApp,
  header: "App",
};

const tourLeaderRoutes = {
  path: "/apps/tour-leader",
  name: "Tour Leader",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "uil-book-reader",
  component: TourLeaderApp,
};

const partnerRoutes = {
  path: "/partners",
  name: "Partners",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "uil-users-alt",
  component: Partners,
  exact: true,
};

const partnerDetailRoute = {
  path: "/partners/detail/:id",
  route: PrivateRoute,
  roles: ["Admin"],
  component: PartnerDetail,
  exact: true,
};

const travelAccountingRoutes = {
  path: "/accountings",
  name: "Contabilità Viaggi",
  icon: "mdi mdi-wallet-travel",
  children: [
    {
      path: "/accounting/lista-viagi",
      name: "Lista Viagi",
      component: ListViagiApp,
      route: PrivateRoute,
    },
    {
      path: "/accounting/fornitori",
      name: "Fornitori",
      component: FornitoriApp,
      route: PrivateRoute,
      exact: true,
    },
    {
      path: "/accounting/fornitori/:id",
      name: "Fornitori Edit",
      component: FornitoriEditApp,
      route: PrivateRoute,
      exact: true,
    },
    {
      path: "/accounting/tipologia-fornitori",
      name: "Tipologia Fornitori",
      component: TipologiaFornitoriApp,
      route: PrivateRoute,
    },
    {
      path: "/accounting/simulazione-costo/:id",
      name: "Simulazione Costo",
      component: SimulazioneCostoApp,
      route: PrivateRoute,
    },
    {
      path: "/accounting/aggiungi-costo/:id",
      name: "Aggiungi Costo",
      component: AggiungiCostoApp,
      route: PrivateRoute,
    },
  ],
  header: "Contabilità",
};

const agencyAccountingRoutes = {
  path: "/agency-accounting",
  name: "Contabilità Agenzia",
  icon: "mdi mdi-receipt",
  children: [
    {
      path: "/agency-accounting/costs",
      name: "Costi",
      component: AgencyAccountingCost,
      route: PrivateRoute,
      exact: true,
    },
    {
      path: "/agency-accounting/providers",
      name: "Fornitori",
      component: AgencyAccountingProviders,
      route: PrivateRoute,
      exact: true,
    },
    {
      path: "/agency-accounting/providers/:id",
      name: "Fornitori Edit",
      component: AgencyAccountingProvidersEdit,
      route: PrivateRoute,
      exact: true,
    },
    {
      path: "/agency-accounting/provider-types",
      name: "Tipologia Fornitori",
      component: AgencyAccountingProviderTypes,
      route: PrivateRoute,
      exact: true,
    },
    {
      path: "/agency-accounting/setting",
      name: "Impostazioni",
      exact: true,
      children: [
        {
          path: "/agency-accounting/setting/working-days",
          name: "Working Days",
          component: WorkingDaysCalendar,
          exact: true,
          route: PrivateRoute,
        },
      ],
    },
  ],
};

const playCardFaqsRoute = {
  path: "/pages-setting/play-card-faqs",
  name: "PlayCard FAQs",
  icon: "mdi mdi-frequently-asked-questions",
  header: "Pages Settings",
  route: PrivateRoute,
  component: PlayCardFaqs,
  exact: true,
};

const IndexCommentsRoute = {
  path: "/pages-setting/index-comments",
  name: "Index Comments",
  icon: "mdi mdi-comment-account",
  route: PrivateRoute,
  component: IndexComments,
  exact: true,
};

const IndexLogosRoute = {
  path: "/pages-setting/index-logos",
  name: "Index Logos",
  icon: "mdi mdi-message-image-outline",
  route: PrivateRoute,
  component: IndexLogos,
  exact: true,
};

const IndexMomentsRoute = {
  path: "/pages-setting/index-moments",
  name: "Index Moments",
  icon: "mdi mdi-message-image-outline",
  route: PrivateRoute,
  component: IndexMoments,
  exact: true,
};

const BannerRoute = {
  path: "/pages-setting/banner",
  name: "Banner",
  icon: "mdi mdi-nature",
  route: PrivateRoute,
  component: Banner,
  exact: true,
};

const IndexCategoriesRoute = {
  path: "/pages-setting/index-categories",
  name: "Index Categories",
  icon: "mdi mdi-tag-multiple",
  route: PrivateRoute,
  component: IndexCategories,
  exact: true,
};

const IndexHeroRoute = {
  path: "/pages-setting/index-hero",
  name: "Index Hero",
  icon: "mdi mdi-cards-diamond-outline",
  route: PrivateRoute,
  component: IndexHero,
  exact: true,
};

const BusRoute = {
  path: "/bus",
  name: "Bus",
  icon: "mdi mdi-bus",
  route: PrivateRoute,
  component: Bus,
  exact: true,
  roles: ["admin"],
};

const appRoutes = [
  notificationAppRoutes,
  tourLeaderRoutes,
  partnerRoutes,
  partnerDetailRoute,
  travelAccountingRoutes,
  agencyAccountingRoutes,
  playCardFaqsRoute,
  IndexCommentsRoute,
  IndexLogosRoute,
  IndexMomentsRoute,
  BannerRoute,
  IndexCategoriesRoute,
  IndexHeroRoute,
  BusRoute,
];

// auth
const authRoutes = {
  path: "/account",
  name: "Auth",
  children: [
    {
      path: "/account/login",
      name: "Login",
      component: Login,
      route: Route,
    },
    {
      path: "/account/logout",
      name: "Logout",
      component: Logout,
      route: Route,
    },
    {
      path: "/account/register",
      name: "Register",
      component: Register,
      route: Route,
    },
    {
      path: "/account/confirm",
      name: "Confirm",
      component: Confirm,
      route: Route,
    },
    {
      path: "/account/forget-password",
      name: "Forget Password",
      component: ForgetPassword,
      route: Route,
    },
  ],
};

// flatten the list of all nested routes
const flattenRoutes = (routes) => {
  let flatRoutes = [];

  routes = routes || [];
  routes.forEach((item) => {
    flatRoutes.push(item);

    if (typeof item.children !== "undefined") {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

// All routes
const allRoutes = [homeRoutes, ...appRoutes, authRoutes];

const authProtectedRoutes = [homeRoutes, ...appRoutes];

const allFlattenRoutes = flattenRoutes(allRoutes);

export { allRoutes, authProtectedRoutes, allFlattenRoutes };
