// @flow
import { FETCH_DATA, FETCH_DATA_SUCCESS, FETCH_DATA_FAILED } from "./constants";

type AuthAction = { type: string, payload: {} | string };

export const fetchData = (): AuthAction => ({
  type: FETCH_DATA,
});

export const fetchDataSuccess = (data: {}): AuthAction => ({
  type: FETCH_DATA_SUCCESS,
  payload: data,
});

export const fetchDataFailed = (error: string): AuthAction => ({
  type: FETCH_DATA_FAILED,
  payload: error,
});
