// @flow
import { all, call, fork, put, takeLatest } from "redux-saga/effects";

import { FETCH_DATA } from "./constants";

import { fetchDataSuccess, fetchDataFailed } from "./actions";

const fetchJSON = (url, options = {}) => {
  return fetch(url, options)
    .then((response) => {
      if (!response.status === 200) {
        throw response.json();
      }
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      throw error;
    });
};

function* fetchData() {
  try {
    const response = yield call(
      fetchJSON,
      "https://hn.algolia.com/api/v1/search?query=redux",
    );
    yield put(fetchDataSuccess(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      default:
        message = error;
    }
    yield put(fetchDataFailed(message));
  }
}

export function* watchFetchData(): any {
  yield takeLatest(FETCH_DATA, fetchData);
}

function* pagesSaga(): any {
  yield all([fork(watchFetchData)]);
}

export default pagesSaga;
